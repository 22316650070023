// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { UserPinResetAction, ResetUserPinActionTypes } from './action';

export interface UserPinResetState {
  userId?: string;
  isOpenModal: boolean;
  loading: boolean;
  isSuccess: boolean;
  error?: Error;
}

export const initialUserPinResetState: UserPinResetState = {
  isOpenModal: false,
  loading: false,
  isSuccess: false,
};

export default function resetUserPinReducer(
  state = initialUserPinResetState,
  action: UserPinResetAction,
) {
  switch (action.type) {
    case ResetUserPinActionTypes.RESET_USER_PIN_REQUEST:
      return update(state, {
        userId: { $set: action.id },
        loading: { $set: true },
      });

    case ResetUserPinActionTypes.RESET_USER_PIN_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isOpenModal: { $set: false },
        isSuccess: { $set: true },
      });

    case ResetUserPinActionTypes.RESET_USER_PIN_FAILURE:
      return update(state, {
        error: { $set: action.error },
        isOpenModal: { $set: false },
        loading: { $set: false },
      });

    case ResetUserPinActionTypes.TOGGLE_MODAL:
      return update(state, {
        isOpenModal: { $set: !state.isOpenModal },
      });

    case ResetUserPinActionTypes.CLOSE_ERROR_MODAL:
      return update(state, {
        error: { $set: null },
      });

    case ResetUserPinActionTypes.CLOSE_SUCCESS_MODAL:
      return update(state, {
        isSuccess: { $set: false },
      });

    default:
      return state;
  }
}
