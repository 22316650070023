/* External dependencies */
import gql from 'graphql-tag';

export const listCashCollectionsQuery = gql`
  query listCashCollections($input: ListCashCollectionsInput!) {
    listCashCollections(input: $input) {
      cashCollections {
        id
        deviceId
        operation
        changeDate
        cashCollectionStatus
        collectedBillsDetail {
          operator {
            id
            firstName
            lastName
          }
        }
      }
      total
      totalDebt
    }
  }
`;
