// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local dependencies
import { getClient } from '../../../../clients/averspay';
import { User } from '../../userTypes';
import {
  ResetUserPin,
  ResetUserPinActionTypes,
  resetUserPinFailure,
  resetUserPinSuccess,
  UserPinResetAction,
} from './action';
import { resetUserPinMutation } from './mutation';

export function resetUserPinEpic(action$) {
  return action$.pipe(
    filter((action: UserPinResetAction) => action.type === ResetUserPinActionTypes.RESET_USER_PIN_REQUEST),
    switchMap((action: ResetUserPin) => resetUserPin(action).then(resetUserPinSuccess).catch(resetUserPinFailure)),
  );
}

export async function resetUserPin(action): Promise<User> {
  const graphQLClient = await getClient();
  const { id } = action;

  const {
    data: { resetUserPin },
  } = await graphQLClient.mutate({
    mutation: resetUserPinMutation,
    variables: {
      input: {
        id,
      },
    },
  });

  return resetUserPin as User;
}
