/* External dependencies */
import gql from 'graphql-tag';

export const receiveCashCollectionMutation = gql`
  query receiveCashCollection($input: ReceiveCashCollectionInput!) {
    receiveCashCollection(input: $input) {
      id
    }
  }
`;
