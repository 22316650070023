/* Local dependencies */
import { UIException } from '../../../redux/exceptions';

export class AmountRequiredException extends UIException {
  code = 'AmountRequiredException';
}

export class NoteRequiredException extends UIException {
  code = 'NoteRequiredException';
}

export class DestinationAccountIdRequiredException extends UIException {
  code = 'destinationAccountIdRequiredException';
}
export class SourceAccountIdRequiredException extends UIException {
  code = 'sourceAccountIdRequiredException';
}
