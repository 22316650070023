/* External dependencies */
import gql from 'graphql-tag';

export const listTransactionsQuery = gql`
  query listTransactions($input: ListTransactionsInput!) {
    listTransactions(input: $input) {
      total
      payments {
        amount
        accountId
        client {
          id
          name
          quickpayId
        }
        fields {
          name
          value
        }
        id
        note
        recipient {
          id
        }
        requestDate
        receiptNumber
        status
        statusMessage
        sender {
          id
          firstName
          lastName
          phone
        }
        senderAccount {
          id
          name
        }
        service {
          id
          logo {
            url
          }
          name_en
          name_ky
          name_ru
          requiredFields {
            fieldId
            keyboardType
            label_en
            label_ky
            label_ru
          }
        }
        transactionDate
        transactionId
        transactionType
        userId
      }
    }
  }
`;

export const listPaymentsQuery = gql`
  query listPayments($input: ListPaymentsInput!) {
    listPayments(input: $input) {
      total
      payments {
        amount
        accountId
        fields {
          name
          value
        }
        id
        note
        recipient {
          id
        }
        requestDate
        receiptNumber
        status
        statusMessage
        sender {
          id
          firstName
          lastName
          phone
        }
        senderAccount {
          id
          name
        }
        service {
          id
          logo {
            url
          }
          name_en
          name_ky
          name_ru
          requiredFields {
            fieldId
            keyboardType
            label_en
            label_ky
            label_ru
          }
        }
        transactionDate
        transactionId
        transactionType
        userId
      }
    }
  }
`;
