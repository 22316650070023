/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { SessionUser } from 'components/login/redux/actions';
import { getClient } from '../../../clients/averspay';
import { getUserRole } from '../../../components/common/helpers';
import { UserRole } from '../../../components/common/roles';
import { getEndOfDay } from '../helper';
import {
  ListTransactions,
  ListTransactionsAction,
  ListTransactionsActionTypes,
  listTransactionsFailed,
  listTransactionsSucceeded,
  ListTransactionsSuccess,
} from './actions';
import { listPaymentsQuery, listTransactionsQuery } from './queries';
import { Transaction } from './types';

export default function listTransactionsEpic( action$, state$ ) {
  return action$.pipe(
    filter( ( action: ListTransactionsAction ) => action.type === ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST ),
    debounceTime( 500 ),
    switchMap( ( action: ListTransactions ) =>
      listTransactions( action, state$.value.transactions?.size, state$.value.login.currentUser ).catch( ( error ) =>
        listTransactionsFailed( error ),
      ),
    ),
  );
}

export async function listTransactions(
  { currentPage, filter, locale, searchString, startDate, endDate }: ListTransactions,
  size: number,
  user: SessionUser,
): Promise<ListTransactionsSuccess> {
  const graphQLClient = await getClient();
  const userRole = getUserRole( user );

  const input = {
    filter,
    from: ( currentPage - 1 ) * size,
    locale,
    query: searchString,
    size,
    ...( startDate ? { startDate: new Date( startDate ).getTime() } : {} ),
    ...( endDate ? { endDate: getEndOfDay( new Date( endDate ) ).getTime() } : {} ),
  };

  const response = await graphQLClient.query( {
    errorPolicy: 'none',
    query: getQuery( userRole ),
    variables: {
      input,
    },
  } );

  const {
    data
  } = response;

  const { total, payments } = data.listTransactions ?? data.listPayments

  const filteredPayments = payments.filter( ( payment: Transaction ) => payment );

  return listTransactionsSucceeded( total, filteredPayments );
}

export function listTransactionsUserEpic( action$, state$ ) {
  return action$.pipe(
    filter(
      ( action: ListTransactionsAction ) => action.type === ListTransactionsActionTypes.LIST_TRANSACTIONS_USER_REQUEST,
    ),
    debounceTime( 500 ),
    switchMap( ( action: ListTransactions ) =>
      listTransactionsUser( action, state$.value.transactions?.size ).catch( ( error ) => listTransactionsFailed( error ) ),
    ),
  );
}

export async function listTransactionsUser(
  { startDate, endDate, currentPage, filter, locale, searchString }: ListTransactions,
  size: number,
): Promise<ListTransactionsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listTransactions: { total, payments },
    },
  } = await graphQLClient.query( {
    query: listTransactionsQuery,
    variables: {
      input: {
        filter,
        from: ( currentPage - 1 ) * size,
        locale,
        query: searchString,
        size,
        startDate: new Date( startDate ).getTime(),
        endDate: new Date( endDate ).getTime(),
      },
    },
  } );

  return listTransactionsSucceeded( total, payments );
}


function getQuery( userRole: string ) {
  switch ( userRole ) {
    case UserRole.USER:
    case UserRole.DEALER:
      return listPaymentsQuery

    default:
      return listPaymentsQuery
  }
}