/* External dependencies */
import gql from 'graphql-tag';

export const resetUserPinMutation = gql`
  mutation resetUserPin($input: ResetUserPinInput!) {
    resetUserPin(input: $input){
      firstName
    }
  }
`;
