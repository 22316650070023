/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { DeviceChange } from '../../../../components/cashCollections/types';
import { DepositCashCollectionActions, DepositCashCollectionTypes } from './action';

export interface DepositCashCollectionState {
  error?: Error;
  loading?: boolean;
  cashCollection?: DeviceChange;
  note?: string;
  isSuccess?: boolean;
}

export const initialDepositCashCollectionState: DepositCashCollectionState = {
  loading: false,
  error: null,
  note: '',
  isSuccess: false,
};

export default function depositCashCollectionReducer(
  state = initialDepositCashCollectionState,
  action: DepositCashCollectionActions,
) {
  switch (action.type) {
    case DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_REQUEST:
      return update(state, {
        loading: { $set: true },
        note: { $set: action.note },
      });

    case DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_SUCCESS:
      return update(state, {
        cashCollection: { $set: action.cashCollection },
        isSuccess: { $set: true },
        loading: { $set: false },
      });

    case DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_RESET:
      return update(state, { $set: initialDepositCashCollectionState });

    default:
      return state;
  }
}
