/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';
import { getClient } from '../../../../clients/averspay';

/* Local dependencies */
import {
  DepositCashCollection,
  DepositCashCollectionActions,
  depositCashCollectionFailed,
  depositCashCollectionSuccess,
  DepositCashCollectionSuccess,
  DepositCashCollectionTypes,
} from './action';
import { depositCashCollectionMutation } from './mutation';

export default function depositCashCollectionEpic(action$) {
  return action$.pipe(
    filter(
      (action: DepositCashCollectionActions) =>
        action.type === DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_REQUEST,
    ),
    switchMap((action: DepositCashCollection) =>
      depositCashCollection(action)
        .then((action) => depositCashCollectionSuccess(action.cashCollection))
        .catch((error) => depositCashCollectionFailed(error)),
    ),
  );
}

export async function depositCashCollection({ id, note }): Promise<DepositCashCollectionSuccess> {
  const graphQLClient = await getClient();

  const input = {
    id,
    note,
  };

  const {
    data: { depositCashCollection },
  } = await graphQLClient.mutate({
    mutation: depositCashCollectionMutation,
    variables: {
      input,
    },
  });

  return depositCashCollectionSuccess(depositCashCollection);
}
