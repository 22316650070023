/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { DeviceChange, ListCashCollectionsFilterInput } from '../types';
import { ListCashCollectionsActions, ListCashCollectionsActionTypes } from './actions';

export interface ListCashCollectionsState {
  error?: Error;
  loading?: boolean;
  cashCollections?: DeviceChange[];
  total?: number;
  totalDebt?: number;
  filter?: ListCashCollectionsFilterInput;
  currentPage: number;
  size: number;
}

export const initialListCashCollectionsState: ListCashCollectionsState = {
  size: 20,
  total: 0,
  totalDebt: 0,
  cashCollections: [],
  loading: true,
  currentPage: 1,
};

export default function listCashCollectionsReducer(
  state = initialListCashCollectionsState,
  action: ListCashCollectionsActions,
) {
  switch (action.type) {
    case ListCashCollectionsActionTypes.LIST_CASH_COLLECTION_REQUEST:
      return update(state, {
        loading: { $set: true },
        filter: { $set: action.filter },
        currentPage: { $set: action.currentPage },
        size: { $set: action.size },
      });

    case ListCashCollectionsActionTypes.LIST_CASH_COLLECTION_SUCCESS:
      return update(state, {
        loading: { $set: false },
        cashCollections: { $set: action.cashCollections },
        total: { $set: action.total },
        totalDebt: { $set: action.totalDebt },
      });
    case ListCashCollectionsActionTypes.LIST_CASH_COLLECTION_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}
