/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import {
  ReceiveCashCollection,
  ReceiveCashCollectionActions,
  receiveCashCollectionFailed,
  ReceiveCashCollectionSuccess,
  receiveCashCollectionSuccess,
  ReceiveCashCollectionTypes,
} from './actions';
import { receiveCashCollectionMutation } from './mutation';

export default function receiveCashCollectionEpic(action$) {
  return action$.pipe(
    filter(
      (action: ReceiveCashCollectionActions) =>
        action.type === ReceiveCashCollectionTypes.RECEIVE_CASH_COLLECTION_REQUEST,
    ),
    switchMap((action: ReceiveCashCollection) =>
      receiveCashCollection(action.receiveCashCollection)
        .then((action) => receiveCashCollectionSuccess(action.cashCollection))
        .catch((error) => receiveCashCollectionFailed(error)),
    ),
  );
}

export async function receiveCashCollection({ id, note, actualBills }): Promise<ReceiveCashCollectionSuccess> {
  const graphQLClient = await getClient();

  const input = {
    id,
    note,
    actualBills,
  };

  const {
    data: { receiveCashCollection },
  } = await graphQLClient.mutate({
    mutation: receiveCashCollectionMutation,
    variables: {
      input,
    },
  });

  return receiveCashCollectionSuccess(receiveCashCollection);
}
