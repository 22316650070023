/* Local dependencies */
import { CashCollectionStatus, DeviceChange, ListCashCollectionsFilterInput } from '../types';

export enum ListCashCollectionsActionTypes {
  LIST_CASH_COLLECTION_REQUEST = 'LIST_CASH_COLLECTION_REQUEST',
  LIST_CASH_COLLECTION_SUCCESS = 'LIST_CASH_COLLECTION_SUCCESS',
  LIST_CASH_COLLECTION_ERROR = 'LIST_CASH_COLLECTION_ERROR',
}

export interface ListCashCollections {
  type: ListCashCollectionsActionTypes.LIST_CASH_COLLECTION_REQUEST;
  filter: ListCashCollectionsFilterInput;
  currentPage: number;
  size: number;
}

export interface ListCashCollectionsSuccess {
  type: ListCashCollectionsActionTypes.LIST_CASH_COLLECTION_SUCCESS;
  cashCollections: DeviceChange[];
  total: number;
  totalDebt: number;
}

export interface ListCashCollectionsError {
  type: ListCashCollectionsActionTypes.LIST_CASH_COLLECTION_ERROR;
  error: Error;
}

export type ListCashCollectionsActions = ListCashCollections | ListCashCollectionsError | ListCashCollectionsSuccess;

export interface CashCollectionsInput {
  filter: ListCashCollectionsFilterInput;
  size: number;
  currentPage: number;
}

export function listCashCollections(params: CashCollectionsInput): ListCashCollections {
  return {
    type: ListCashCollectionsActionTypes.LIST_CASH_COLLECTION_REQUEST,
    ...params,
  };
}

export function listCashCollectionsSuccess(
  cashCollections: DeviceChange[],
  total: number,
  totalDebt: number,
): ListCashCollectionsSuccess {
  return {
    type: ListCashCollectionsActionTypes.LIST_CASH_COLLECTION_SUCCESS,
    cashCollections,
    total,
    totalDebt,
  };
}

export function listCashCollectionsFailed(error: Error): ListCashCollectionsError {
  return {
    type: ListCashCollectionsActionTypes.LIST_CASH_COLLECTION_ERROR,
    error,
  };
}
