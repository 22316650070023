// External dependencies
import { debounceTime, filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '../../../clients/averspay';
import {
  ListUsers,
  ListUsersAction,
  ListUsersActionTypes,
  listUsersFailed,
  listUsersSucceeded,
  ListUsersSuccess,
} from './actions';
import { listUsersForManagerQuery, listUsersQuery } from './queries';

export default function listUsersEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListUsersAction) => action.type === ListUsersActionTypes.LIST_USERS_REQUEST),
    debounceTime(500),
    switchMap((action: ListUsers) =>
      listUsers(action, state$?.value?.users?.size).catch((error) => listUsersFailed(error)),
    ),
  );
}

export async function listUsers(
  { currentPage, searchString, filter }: ListUsers,
  size: number,
): Promise<ListUsersSuccess> {
  const graphQLClient = await getClient();

  const { data } = await graphQLClient.query({
    query: listUsersQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        query: searchString,
        size,
        filter,
      },
    },
  });

  const validUsers = data?.listUsers?.users?.filter((user) => user?.id) || [];

  return listUsersSucceeded(data?.listUsers?.total || 0, validUsers);
}

export function listUsersForManagerEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListUsersAction) => action.type === ListUsersActionTypes.LIST_USERS_REQUEST_MANAGER),
    debounceTime(500),
    switchMap((action: ListUsers) =>
      listUsersForManager(action, state$?.value?.users?.size).catch((error) => listUsersFailed(error)),
    ),
  );
}

export async function listUsersForManager(
  { currentPage, searchString, filter }: ListUsers,
  size: number,
): Promise<ListUsersSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listDealers: { users, total },
    },
  } = await graphQLClient.query({
    query: listUsersForManagerQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        query: searchString,
        size,
        filter,
      },
    },
  });

  return listUsersSucceeded(total, users);
}
