/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import {
  ListCashCollections,
  ListCashCollectionsActions,
  ListCashCollectionsActionTypes,
  listCashCollectionsFailed,
  listCashCollectionsSuccess,
  ListCashCollectionsSuccess,
} from './actions';
import { listCashCollectionsQuery } from './queries';

export default function listCashCollectionsEpic(action$) {
  return action$.pipe(
    filter(
      (action: ListCashCollectionsActions) =>
        action.type === ListCashCollectionsActionTypes.LIST_CASH_COLLECTION_REQUEST,
    ),
    switchMap((action: ListCashCollections) =>
      listCashCollection(action).catch((error) => listCashCollectionsFailed(error)),
    ),
  );
}

export async function listCashCollection({ filter, currentPage, size }): Promise<ListCashCollectionsSuccess> {
  const graphQLClient = await getClient();

  const input = {
    filter,
    from: (currentPage - 1) * size,
    size,
  };

  const {
    data: {
      listCashCollections: { cashCollections, total, totalDebt },
    },
  } = await graphQLClient.query({
    query: listCashCollectionsQuery,
    variables: {
      input,
    },
  });

  return listCashCollectionsSuccess(cashCollections, total, totalDebt);
}
