/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { QueueMetricsAction, QueueMetricsActionTypes, RedriveTransactionQueueResponse } from './action';

export interface QueueMetricsState {
  error?: Error;
  isSuccess: boolean;
  loading?: boolean;
  redriveQueueSuccess?: RedriveTransactionQueueResponse;
  isRedriveSuccess: boolean;
  dlqInProgressMessageCount: string;
  dlqPendingMessageCount: string;
  sqsInProgressMessageCount: string;
  sqsPendingMessageCount: string;
  isInProgressDqlRedriveLoading: boolean;
  isPendingDqlRedriveLoading: boolean;
}

export const initialQueueMetricsState: QueueMetricsState = {
  isSuccess: false,
  loading: false,
  dlqInProgressMessageCount: '',
  dlqPendingMessageCount: '',
  sqsInProgressMessageCount: '',
  sqsPendingMessageCount: '',
  isRedriveSuccess: false,
  isPendingDqlRedriveLoading: false,
  isInProgressDqlRedriveLoading: false,
};

export default function transactionsQueueMetrics(state = initialQueueMetricsState, action: QueueMetricsAction) {
  switch (action.type) {
    case QueueMetricsActionTypes.QUEUE_METRICS_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        isSuccess: { $set: false },
      });

    case QueueMetricsActionTypes.QUEUE_METRICS_SUCCESS:
      const { dlqInProgressMessageCount, dlqPendingMessageCount, sqsInProgressMessageCount, sqsPendingMessageCount } =
        action.values;

      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        dlqInProgressMessageCount: { $set: dlqInProgressMessageCount },
        dlqPendingMessageCount: { $set: dlqPendingMessageCount },
        sqsInProgressMessageCount: { $set: sqsInProgressMessageCount },
        sqsPendingMessageCount: { $set: sqsPendingMessageCount },
      });

    case QueueMetricsActionTypes.QUEUE_METRICS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case QueueMetricsActionTypes.QUEUE_METRICS_RESET_STATE:
      return update(state, { $set: initialQueueMetricsState });

    case QueueMetricsActionTypes.QUEUE_METRICS_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case QueueMetricsActionTypes.REDRIVE_PENDING_QUEUE_METRICS_REQUEST:
      return update(state, {
        $unset: ['error'],
        isRedriveSuccess: { $set: false },
        isPendingDqlRedriveLoading: { $set: true },
      });

    case QueueMetricsActionTypes.REDRIVE_PENDING_QUEUE_METRICS_SUCCESS:
      return update(state, {
        redriveQueueSuccess: { $set: action.values },
        isRedriveSuccess: { $set: true },
        isPendingDqlRedriveLoading: { $set: false },
      });

    case QueueMetricsActionTypes.REDRIVE_PENDING_QUEUE_METRICS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
        isRedriveSuccess: { $set: false },
        isPendingDqlRedriveLoading: { $set: false },
      });

    case QueueMetricsActionTypes.REDRIVE_IN_PROGRESS_QUEUE_METRICS_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        isRedriveSuccess: { $set: false },
        isInProgressDqlRedriveLoading: { $set: true },
      });

    case QueueMetricsActionTypes.REDRIVE_IN_PROGRESS_QUEUE_METRICS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        redriveQueueSuccess: { $set: action.values },
        isRedriveSuccess: { $set: true },
        isInProgressDqlRedriveLoading: { $set: false },
      });

    case QueueMetricsActionTypes.REDRIVE_IN_PROGRESS_QUEUE_METRICS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
        isRedriveSuccess: { $set: false },
        isInProgressDqlRedriveLoading: { $set: false },
      });

    default:
      return state;
  }
}
