/*External dependencies */
import { filter, switchMap } from 'rxjs';

/*Local dependencies */
import { getClient } from '../../../clients/averspay';
import {
  ListLimitsAction,
  ListLimitsActionTypes,
  listLimitsFailed,
  listLimitsSucceeded,
  ListLimitsSuccess,
} from './actions';
import { listLimitsQuery } from './queries';

export default function listLimitsEpic(action$) {
  return action$.pipe(
    filter((action: ListLimitsAction) => action.type === ListLimitsActionTypes.LIST_LIMITS_REQUEST),
    switchMap(() => listLimits().catch((error) => listLimitsFailed(error))),
  );
}

export async function listLimits(): Promise<ListLimitsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listLimits: { limits, total },
    },
  } = await graphQLClient.query({
    query: listLimitsQuery,
    variables: {
      input: {},
    },
  });

  return listLimitsSucceeded(limits, total);
}
