// External dependencies
import { ApolloClient } from '@apollo/client';
import { ICognitoUserPoolData } from 'amazon-cognito-identity-js';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

// Local dependencies
import { COGNITO_USER_POOL_DATA } from '../../../clients/cognito';
import CustomCognitoUserSession from '../../../clients/cognitoUserSession';
import { UserRole } from '../../common/roles';

export enum LoginActionTypes {
  INIT_CLIENT = 'INIT_CLIENT',
  INIT_CLIENT_FAILED = 'INIT_CLIENT_FAILED',
  INIT_CLIENT_SUCCEEDED = 'INIT_CLIENT_SUCCEEDED',
  INIT_ANANONYMOUS_CLIENT = 'INIT_ANANONYMOUS_CLIENT',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGIN_SUCCEEDID = 'LOGIN_SUCCEEDID',
  LOGOUT_ERROR = 'LOGOUT_ERROR',
  SEND_VERIFICATION_CODE_REQUEST = 'SEND_VERIFICATION_CODE_REQUEST',
  SET_USER_SESSION = 'SET_USER_SESSION',
  SET_PHONE_NUMBER = 'SET_PHONE_NUMBER'
}

export function initClient(): InitClient {
  return {
    type: LoginActionTypes.INIT_CLIENT,
  };
}

export function initClientFailed(): InitClientFailed {
  return {
    type: LoginActionTypes.INIT_CLIENT_FAILED,
  };
}

export function initClientSucceeded( session: CustomCognitoUserSession ): InitClientSucceeded {
  return {
    type: LoginActionTypes.INIT_CLIENT_SUCCEEDED,
    session,
  };
}

export function initAnanonymousClient( client: ApolloClient<NormalizedCacheObject> ) {
  return {
    type: LoginActionTypes.INIT_ANANONYMOUS_CLIENT,
    client,
  };
}

export function login( username: string, userPool: ICognitoUserPoolData ): LoginRequest {
  return {
    type: LoginActionTypes.LOGIN_REQUEST,
    username,
    userPool,
  };
}

export function loginFailed( error: Error ): LoginError {
  return {
    type: LoginActionTypes.LOGIN_ERROR,
    error,
  };
}

export function logout(): LogoutRequest {
  return {
    type: LoginActionTypes.LOGOUT_REQUEST,
  };
}

export function logoutFailed( error: Error ): LogoutError {
  return {
    type: LoginActionTypes.LOGOUT_ERROR,
    error,
  };
}

export function logoutSucceeded(): LogoutSuccess {
  return {
    type: LoginActionTypes.LOGOUT_SUCCESS,
  };
}

export function sendVerificationCodeRequest(
  username: string,
  verificationCode: string,
  userPool = COGNITO_USER_POOL_DATA,
): SendVerificationCodeRequest {
  return {
    type: LoginActionTypes.SEND_VERIFICATION_CODE_REQUEST,
    username,
    verificationCode,
    userPool,
  };
}

export function setUserSession( currentUserSession: string ): SetUserSession {
  return {
    type: LoginActionTypes.SET_USER_SESSION,
    currentUserSession,
  };
}

export function setPhoneNumber( phoneNumber: { formatted: string; onlyNumbers: string } ): PhoneNumber {
  return {
    type: LoginActionTypes.SET_PHONE_NUMBER,
    phoneNumber,
  };
}

export interface InitClient {
  type: LoginActionTypes.INIT_CLIENT;
}

export interface InitClientFailed {
  type: LoginActionTypes.INIT_CLIENT_FAILED;
}

export interface InitClientSucceeded {
  type: LoginActionTypes.INIT_CLIENT_SUCCEEDED;
  session: CustomCognitoUserSession;
}

export interface InitAnanonymousClient {
  type: LoginActionTypes.INIT_ANANONYMOUS_CLIENT;
  client: ApolloClient<NormalizedCacheObject>;
}

export interface PhoneNumber {
  type: LoginActionTypes.SET_PHONE_NUMBER,
  phoneNumber?: {
    formatted: string,
    onlyNumbers: string,
  }
}

export interface LoginRequest {
  type: LoginActionTypes.LOGIN_REQUEST;
  username: string;
  userPool: ICognitoUserPoolData;
}

export interface LoginError {
  type: LoginActionTypes.LOGIN_ERROR;
  error: Error;
}

export interface LogoutRequest {
  type: LoginActionTypes.LOGOUT_REQUEST;
}

export interface LogoutError {
  type: LoginActionTypes.LOGOUT_ERROR;
  error: Error;
}

export interface LogoutSuccess {
  type: LoginActionTypes.LOGOUT_SUCCESS;
}

export interface LogoutError {
  type: LoginActionTypes.LOGOUT_ERROR;
  error: Error;
}

export interface SendVerificationCodeRequest {
  type: LoginActionTypes.SEND_VERIFICATION_CODE_REQUEST;
  currentUserSession?: string;
  username: string;
  verificationCode: string;
  userPool: ICognitoUserPoolData;
}

export interface SetUserSession {
  type: LoginActionTypes.SET_USER_SESSION;
  currentUserSession: string;
}

export type LoginAction =
  | InitClient
  | InitClientFailed
  | InitClientSucceeded
  | InitAnanonymousClient
  | LoginRequest
  | LoginError
  | LogoutRequest
  | LogoutSuccess
  | LogoutError
  | SendVerificationCodeRequest
  | SetUserSession
  | PhoneNumber;

export interface SessionUser {
  aud;
  auth_time;
  'cognito:preferred_role': string;
  email;
  email_verified;
  event_id;
  exp;
  iat;
  iss;
  groups: UserRole;
  jti;
  origin_jti;
  phone_number;
  phone_number_verified;
  roles: UserRole;
  sub;
  token_use;
}
