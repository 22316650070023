/* External dependencies */
import gql from 'graphql-tag';

export const depositCashCollectionMutation = gql`
  query depositCashCollection($input: DepositCashCollectionInput!) {
    depositCashCollection(input: $input) {
      id
    }
  }
`;
