export enum ResetUserPinActionTypes {
  RESET_USER_PIN_REQUEST = 'RESET_USER_PIN_REQUEST',
  RESET_USER_PIN_SUCCESS = 'RESET_USER_PIN_SUCCESS',
  RESET_USER_PIN_FAILURE = 'RESET_USER_PIN_FAILURE',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  CLOSE_ERROR_MODAL = 'CLSE_ERROR_MODAL',
  CLOSE_SUCCESS_MODAL = 'CLOSE_SUCCESS_MODAL',
}

export interface ResetUserPin {
  type: ResetUserPinActionTypes.RESET_USER_PIN_REQUEST;
  id: string;
}

export interface ResetUserPinSuccess {
  type: ResetUserPinActionTypes.RESET_USER_PIN_SUCCESS;
}

export interface ResetUserPinFailure {
  type: ResetUserPinActionTypes.RESET_USER_PIN_FAILURE;
  error: Error;
}
export interface ToggleModal {
  type: ResetUserPinActionTypes.TOGGLE_MODAL;
}

export interface CloseErrorModal {
  type: ResetUserPinActionTypes.CLOSE_ERROR_MODAL;
}

export interface CloseSuccessModal {
  type: ResetUserPinActionTypes.CLOSE_SUCCESS_MODAL;
}

export type UserPinResetAction =
  | ResetUserPin
  | ResetUserPinSuccess
  | ResetUserPinFailure
  | CloseErrorModal
  | ToggleModal
  | CloseSuccessModal;

export const resetUserPin = (id: string): ResetUserPin => ({
  type: ResetUserPinActionTypes.RESET_USER_PIN_REQUEST,
  id,
});

export const resetUserPinSuccess = (): ResetUserPinSuccess => ({
  type: ResetUserPinActionTypes.RESET_USER_PIN_SUCCESS,
});

export const resetUserPinFailure = (error: Error): ResetUserPinFailure => ({
  type: ResetUserPinActionTypes.RESET_USER_PIN_FAILURE,
  error,
});

export const toggleResetUserPinModal = (): ToggleModal => ({
  type: ResetUserPinActionTypes.TOGGLE_MODAL,
});

export const closeErrorModal = (): CloseErrorModal => ({
  type: ResetUserPinActionTypes.CLOSE_ERROR_MODAL,
});

export const closeSuccessModal = (): CloseSuccessModal => ({
  type: ResetUserPinActionTypes.CLOSE_SUCCESS_MODAL,
});
