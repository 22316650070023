/*Local dependencies */
import { DeviceChange } from '../../../../components/cashCollections/types';

export enum DepositCashCollectionTypes {
  DEPOSIT_CASH_COLLECTION_REQUEST = 'DEPOSIT_CASH_COLLECTION_REQUEST',
  DEPOSIT_CASH_COLLECTION_SUCCESS = 'DEPOSIT_CASH_COLLECTION_SUCCESS',
  DEPOSIT_CASH_COLLECTION_ERROR = 'DEPOSIT_CASH_COLLECTION_ERROR',
  DEPOSIT_CASH_COLLECTION_RESET = 'DEPOSIT_CASH_COLLECTION_RESET',
}

export type DepositCashCollectionActions =
  | DepositCashCollection
  | DepositCashCollectionSuccess
  | DepositCashCollectionError
  | DepositCashCollectionReset;

export interface DepositCashCollection {
  type: DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_REQUEST;
  id: string;
  note: string;
}

export interface DepositCashCollectionSuccess {
  type: DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_SUCCESS;
  cashCollection: DeviceChange;
}

export interface DepositCashCollectionError {
  type: DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_ERROR;
  error: Error;
}

export interface DepositCashCollectionReset {
  type: DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_RESET;
}

export function depositCashCollection(id: string, note: string): DepositCashCollection {
  return {
    type: DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_REQUEST,
    id,
    note,
  };
}

export function depositCashCollectionSuccess(cashCollection: DeviceChange): DepositCashCollectionSuccess {
  return {
    type: DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_SUCCESS,
    cashCollection,
  };
}

export function depositCashCollectionFailed(error: Error): DepositCashCollectionError {
  return {
    type: DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_ERROR,
    error,
  };
}

export function depositCashCollectionReset(): DepositCashCollectionReset {
  return {
    type: DepositCashCollectionTypes.DEPOSIT_CASH_COLLECTION_RESET,
  };
}
