/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { UpdateTopUpBalance } from './actions';
import {
  AmountRequiredException,
  DestinationAccountIdRequiredException,
  NoteRequiredException,
  SourceAccountIdRequiredException,
} from './exceptions';
import { TopUpBalanceState } from './reducer';

export function validateTopUpBalanceFields(state: TopUpBalanceState, updates: UpdateTopUpBalance) {
  const { amount, destinationAccountId, sourceAccountId, note } = updates;
  const change: any = {};

  if (updates.hasOwnProperty('amount')) {
    if (!amount) {
      change.amountError = {
        $set: new AmountRequiredException(),
      };
    } else {
      change['$unset'] = ['amountError'];
    }

    return update(state, {
      ...change,
      topUpBalance: {
        $set: update(state.topUpBalance, {
          amount: { $set: amount },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('note')) {
    if (!note) {
      change.noteError = {
        $set: new NoteRequiredException(),
      };
    } else {
      change['$unset'] = ['noteError'];
    }

    return update(state, {
      ...change,
      topUpBalance: {
        $set: update(state.topUpBalance, {
          note: { $set: note },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('destinationAccountId')) {
    if (!destinationAccountId) {
      change.destinationAccountIdError = {
        $set: new DestinationAccountIdRequiredException(),
      };
    } else {
      change['$unset'] = ['destinationAccountIdError'];
    }

    return update(state, {
      ...change,
      topUpBalance: {
        $set: update(state.topUpBalance, {
          destinationAccountId: { $set: destinationAccountId },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('sourceAccountId')) {
    if (!sourceAccountId) {
      change.sourceAccountIdError = {
        $set: new SourceAccountIdRequiredException(),
      };
    } else {
      change['$unset'] = ['sourceAccountIdError'];
    }

    return update(state, {
      ...change,
      topUpBalance: {
        $set: update(state.topUpBalance, {
          sourceAccountId: { $set: sourceAccountId },
        }),
      },
    });
  }

  return state;
}
