// External dependencies
import { MoneyInput } from '../types';

export interface SetServiceFeesInput {
  id: String;
  lower: ServiceFeesInput;
  serviceId: String;
  upper: ServiceFeesInput;
}

export interface SetServiceFees extends SetServiceFeesInput {}

export interface ServiceFeesInput {
  flatFee?: MoneyInput;
  flatFeePercent?: number;
  dynamicFeeSteps?: ServiceStepFees;
}

export interface ServiceStepFees {
  from: MoneyInput;
  fee: ServiceFeesInput;
}

export enum FeeEntity {
  SERVICE = 'SERVICE',
  TERMINAL = 'TERMINAL',
  USER = 'USER',
  DEFAULT = 'DEFAULT',
}
