/*Local dependencies */
import { ActualBills, DeviceChange } from '../../../../components/cashCollections/types';

export enum ReceiveCashCollectionTypes {
  RECEIVE_CASH_COLLECTION_REQUEST = 'RECEIVE_CASH_COLLECTION_REQUEST',
  RECEIVE_CASH_COLLECTION_SUCCESS = 'RECEIVE_CASH_COLLECTION_SUCCESS',
  RECEIVE_CASH_COLLECTION_ERROR = 'RECEIVE_CASH_COLLECTION_ERROR',
  RECEIVE_CASH_COLLECTION_RESET = 'RECEIVE_CASH_COLLECTION_RESET',
  CHANGE_RECEIVE_CASH_COLLECTION = 'CHANGE_RECEIVE_CASH_COLLECTION',
}

export type ReceiveCashCollectionActions =
  | ReceiveCashCollection
  | ReceiveCashCollectionSuccess
  | ReceiveCashCollectionError
  | ReceiveCashCollectionReset
  | ChangeReceiveCashCollection;

export interface ReceiveCashCollection {
  type: ReceiveCashCollectionTypes.RECEIVE_CASH_COLLECTION_REQUEST;
  receiveCashCollection: {
    id: string;
    note: string;
    actualBills: ActualBills[];
  };
}

export interface ReceiveCashCollectionSuccess {
  type: ReceiveCashCollectionTypes.RECEIVE_CASH_COLLECTION_SUCCESS;
  cashCollection: DeviceChange;
}

export interface ReceiveCashCollectionError {
  type: ReceiveCashCollectionTypes.RECEIVE_CASH_COLLECTION_ERROR;
  error: Error;
}

export interface ReceiveCashCollectionReset {
  type: ReceiveCashCollectionTypes.RECEIVE_CASH_COLLECTION_RESET;
}

export interface ChangeReceiveCashCollection {
  type: ReceiveCashCollectionTypes.CHANGE_RECEIVE_CASH_COLLECTION;
  actualBills: { index: number; count: number };
}

export function receiveCashCollection(id: string, note: string, actualBills: ActualBills[]): ReceiveCashCollection {
  return {
    type: ReceiveCashCollectionTypes.RECEIVE_CASH_COLLECTION_REQUEST,
    receiveCashCollection: { id, note, actualBills },
  };
}

export function receiveCashCollectionSuccess(cashCollection: DeviceChange): ReceiveCashCollectionSuccess {
  return {
    type: ReceiveCashCollectionTypes.RECEIVE_CASH_COLLECTION_SUCCESS,
    cashCollection,
  };
}

export function receiveCashCollectionFailed(error: Error): ReceiveCashCollectionError {
  return {
    type: ReceiveCashCollectionTypes.RECEIVE_CASH_COLLECTION_ERROR,
    error,
  };
}

export function receiveCashCollectionReset(): ReceiveCashCollectionReset {
  return {
    type: ReceiveCashCollectionTypes.RECEIVE_CASH_COLLECTION_RESET,
  };
}

export function changeReceiveCashCollection({ index, count }): ChangeReceiveCashCollection {
  return {
    type: ReceiveCashCollectionTypes.CHANGE_RECEIVE_CASH_COLLECTION,
    actualBills: {
      index,
      count,
    },
  };
}
